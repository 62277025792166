<template>
  <el-dialog
    title="请确认"
    :visible.sync="showConfirmDialog"
    width="500px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <slot />
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'confirmDialog',
  props: {
    showConfirmDialog: false,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    initData() {},
    sureFn() {
      this.$emit('sureConfirm')
    },
    closeFn() {
      this.$emit('closeConfirm')
    },
  },
}
</script>

<style lang="scss" scoped></style>
